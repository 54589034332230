function leftPad(value, length) {
    return ('0'.repeat(length) + value).slice(-length);
}

let totalItemsPop = $('#banner .carousel-item ').length;

let slideTtile = $('.slide-title');
let slideDescription = $('.slide-description');
let slideAtual = $('.slide-atual');
let slideTotal = $('.slide-total');

slideTtile.text($('#banner div.active .title').text());
slideDescription.text($('#banner div.active .description').text());

slideAtual.text('01');
slideTotal.text(leftPad(totalItemsPop, 2));

$('#banner').on('slid.bs.carousel', function () {
    let currentIndexPop = $('#banner div.active').index() + 1;

    slideTtile.text($('#banner div.active .title').text());
    slideDescription.text($('#banner div.active .description').text());
    slideAtual.text(leftPad(currentIndexPop, 2));
    slideTotal.text(leftPad(totalItemsPop, 2));
});
